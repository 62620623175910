
import { Options, Vue } from 'vue-class-component';
import CategoryForm from '@/components/forms/CategoryForm.vue'
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import OperationResult from '@/components/layout/OperationResult.vue';

@Options({
    components: {
        CategoryForm, Breadcrumb, OperationResult
    },
    data() {
        return {
            result: {
                show: false,
                title: '',
                duration: 0,
                toTitle: '',
                backText: '',
                nextText: '',
                status: 'SUCCESS'
            },
            loading: false
        }
    },
    inject: ['menuReload', 'reload'],
    methods:{
        submit(e: any){
            this.loading = true;
            this.$api.xrStoreCategory(e).then((res: any) => {
                console.log(res)
                this.loading = false;
                this.menuReload();
                if(res.msg === 'success'){
                    this.result = {
                        show: true,
                        title: '栏目创建成功',
                        duration: 0,
                        nextText: '继续创建',
                        backText: '返回上一页',
                        status: 'SUCCESS'
                    }
                } else {
                    this.result = {
                        show: true,
                        title: '栏目创建失败',
                        duration: 0,
                        nextText: '继续创建',
                        backText: '返回上一页',
                        status: 'ERROR'
                    }
                }
            })
        }
    }
})

export default class CategoryCreate extends Vue{}
